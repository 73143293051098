<template>
  <div class="content-wrapper">
    <div class="worktime main-content">
      <div class="page-header">
        <h1>Worktime Setting</h1>
      </div>
      <div class="form-wrapper">
        <div class="action-button-wrapper">
          <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
          <button v-if="!disabled" class="btn btn-primary saveButton" @click="updateSettings">Save</button>
        </div>

        <h2 class="card-title">Late / OT Time Limit</h2>

        <div class="card info-card">
          <el-form ref="time-limit-form" :disabled="disabled" :model="form">
            <div class="row">
              <el-form-item class="time-limit-form" label="Late Limits" style="max-width: 500px;">
                <el-input-number v-model="form.lateLimit" :min="1" label="Input number"></el-input-number>
                <el-select v-model="form.lateLimitUnit" placeholder="Select">
                  <el-option
                    v-for="item in limitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>

          <el-form ref="ot-limit-form" :disabled="disabled" :model="form">
            <div class="row">
              <el-form-item class="time-limit-form" label="OT Limits" style="max-width: 500px;">
                <el-input-number v-model="form.otLimit" :min="1" label="Input number"></el-input-number>
                <el-select v-model="form.otLimitUnit" placeholder="Select">
                  <el-option
                    v-for="item in limitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'Worktime',
  data(){
    return {
      disabled: true,
      form: {
        workStartTime: '',
        workEndTime: '',
        lateLimit: '',
        lateLimitUnit: 'minute',
        otLimit: '',
        otLimitUnit: 'minute',
      },
      limitOptions: [
        {
          'value': 'hour',
          'label': 'Hours'
        },
        {
          'value': 'minute',
          'label': 'Minutes'
        }
      ],
    }
  },
  mounted(){
    this.loadTimeSettings();
  },
  methods:{
    startEdit(){
      this.disabled = false;
    },
    async loadTimeSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let workStartTime = await Common.loadConfig(this.apiUrl, 'working_start_time', loginInfo);
        let workEndTime = await Common.loadConfig(this.apiUrl, 'working_end_time', loginInfo);
        let lateLimit = await Common.loadConfig(this.apiUrl, 'late_limit', loginInfo);
        let otLimit = await Common.loadConfig(this.apiUrl, 'ot_limit', loginInfo);

        workStartTime = workStartTime['config_value'];
        workEndTime = workEndTime['config_value'];

        lateLimit = lateLimit['config_value'];
        otLimit = otLimit['config_value'];

        //Get Limit number and unit by regax
        const limitRegax = /([0-9]+) (.+)/;

        const lateLimitValue = limitRegax.exec(lateLimit)[1];
        const lateLimitUnit = limitRegax.exec(lateLimit)[2];
        this.form.lateLimit = lateLimitValue;
        this.form.lateLimitUnit = lateLimitUnit;

        const otLimitValue = limitRegax.exec(otLimit)[1];
        const otLimitUnit = limitRegax.exec(otLimit)[2];
        this.form.otLimit = otLimitValue;
        this.form.otLimitUnit = otLimitUnit;

        this.form.workStartTime = dayjs.unix(workStartTime);
        this.form.workEndTime = dayjs.unix(workEndTime);

        this.disabled = true;
      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        const workStartTime = dayjs(this.form.workStartTime).unix();
        const workEndTime = dayjs(this.form.workEndTime).unix();

        await Common.updateConfig(this.apiUrl, 'working_start_time', workStartTime, loginInfo);
        await Common.updateConfig(this.apiUrl, 'working_end_time', workEndTime, loginInfo);

        const lateLimit = `${this.form.lateLimit} ${this.form.lateLimitUnit}`;
        const otLimit = `${this.form.otLimit} ${this.form.otLimitUnit}`;

        await Common.updateConfig(this.apiUrl, 'late_limit', lateLimit, loginInfo);
        await Common.updateConfig(this.apiUrl, 'ot_limit', otLimit, loginInfo);

        this.disabled = true;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">

.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}

.el-form{
  .time-divider{
    margin-left: 20px;
    margin-right: 20px;
  }
}
.card-title{
  margin-top: 50px;
}

.time-limit-form{
  display: flex;
  .el-form-item__content{
    margin-left: 10px !important;
    display: flex;
  }
  .el-form-item__label{
    text-align: left;
    flex-basis: 30%;
  }
  .el-input-number{
    margin-right: 20px;
  }
}
</style>
